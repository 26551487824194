<template>
  <div class="footer">
    <div class="box1 clearfix">
      <div class="pull-left">
        <div class="name">商务联系</div>
        <ul>
          <li>bd@limaocx.com</li>
        </ul>
      </div>
      <div class="pull-left">
        <div class="name">友情链接</div>
        <ul>
          <li>
            <a href="https://www.levcauto.com/" target="_blank"
              >LEVC官网（中国）</a
            >
          </li>
          <li>
            <a href="https://www.levc.com/" target="_blank">LEVC官网（国际）</a>
          </li>
          <li>
            <a href="http://zgh.com/" target="_blank">吉利控股集团</a>
          </li>
          <!-- <li>
                <a href="https://www.caocaokeji.cn/" target="_blank"
                  >曹操出行</a
                >
              </li>
              <li>
                <a href="https://www.starrides.com/" target="_blank">耀出行</a>
              </li> -->
        </ul>
      </div>
      <div class="pull-left">
        <div class="name">平台相关</div>
        <ul>
          <li>
            <router-link to="/pc_aggrement">保险条款</router-link>
          </li>
          <li>
            <router-link to="/pc_price">价格公示</router-link>
          </li>
          <li>
            <router-link to="/pc_about">关于我们</router-link>
          </li>
          <li>
            <router-link to="/pc_connect">联系我们</router-link>
          </li>
        </ul>
      </div>
      <div class="pull-right">
        <!-- <div>
          <div class="name">礼帽出行APP</div>
          <div>
            <img
              src="../../assets/images/xiazai.png"
              alt=""
              style="width: 86px"
            />
          </div>
          <div class="name">扫码下载</div>
        </div> -->
        <div>
          <div class="name">礼帽出行微信公众号</div>
          <div>
            <img
              src="../../assets/images/weixin.png"
              alt=""
              style="width: 86px"
            />
          </div>
          <div class="name">扫码关注</div>
        </div>
      </div>
    </div>
    <div class="box2">
      浙江礼帽出行科技有限公司 copyright@2021 &nbsp;&nbsp;&nbsp;&nbsp;
      
      <a href="https://beian.miit.gov.cn" target="_blank"
        >浙ICP备2021011876号</a
      >
&nbsp;&nbsp;

       <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010802012380" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;">
       <img
          style="vertical-align: middle"
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAMAAAC6V+0/AAAC3FBMVEUAAAD+/ODz6Kr//+PeqFfYrn3x167k0JXoxnyaaVzhs2ifaFXbrGLkvFnpyF7v2X/kwm3cp1nhsGfqw3rZqG3ntVzjrFPt3oDjvGnfr2fbnFGti3q0lH7ktoLryXn9v1T4znr/74bnvGz034v+2I/ktoDz6ZLkwY/Dfz7buoftzYbq2IPr0pjs3bLv6KPRrnbKhFv79ND488n/+dDZr4Lx38f/+cH/95f42oL7/97s2Y3++uzw1rvTk3DmuloAAHkBAm7uzWYAAGXktV3qvFr/0ljksE7fo0rWHxhrdocAAIAABHf143Pyy27w1GwGA2jtymHpwWDqxV/qyVyTeFrrwFflwFPislP+xVLpsErbmUfVkEbysETemUTpgj7ThT3XdTg5FDjdhTXWZTDaTCm7TCbTOCLXPiD9LA/QFg3UAwnOAQOEj5kcPpdyhZSptJEACJFpfo4AG44XMInFvYfTvIejmYSVkINyeoJzdoK9un6SjX7FrnwAEHp8enny2HjWwHjKtnhcX3jYzHeNhnfu2HWUjHWsonPNwnH70m9WTm8AAW//723pym3dtmn/0mbnxGa0o2ZeWWb8zGT/4mPtwmJuYmL/22D/vmB5ZGC9kF7/2l0MAF3uyFqnjVn4xFjYnli0mVi5i1jiqVfyyVbmtlbXkVNUOFPlvFLpt1LNrFKjfVLuvlBgHlDsuU/ouU9ONU/ov05ODk7/2E02Gk3jqkqEaUr/tUngjkf7n0bXikb6xERCJETdn0LckUG1gD/ooD3Ulj3jkz3TZT3WjjzOeDqBWDr3pDnglTlMADnbbTf2gjbkbzaTYDZpAjbplzTtcTTEazPXXzOeXzDscS3MPi38jizJWSrVSCrrXynzfCjVdCjZRyjTQCbFUiTlYCPXPSHLPSHWMR/wXh7iRh7GPh3PLBrSIRrWGhfMJxPGJxPRDBG/ABG2ABCxDg7BDAvEGArZAAbJAALPAADa4ry/AAAAPnRSTlMACEIaxqxpAvv7+ff19PDs7Ovn5uXk5OHg29LRy8fEw8G+vLqysaufnJiVk4yDfG9dXFpMSEFBNTApJyEcFO3QiBQAAAFzSURBVBjTYoACZjYZaTZmBmRgxsp9+di21ZysxggxxlmJZy/ev9LXnriIEa5VYUPIray0lOyd+ctVoKKWXFsmXXvu8exO5vsZnnuErcCC5m1e8x5nPXrxOu3TzSqHFguQmI18tff+Jx89HqR7fE5v7q5TtAYK6h8v81p4Ovv6wbAdmRc6HMpddYGCmudrCqbtTn2anHBq15SZ9iUx6kBBkSTfXIfUuBsPL909c9i/uP6EJFAQMJ6j2/Ps32Yk30uIy3jjXxgRLwEUVN07ubTo5LsPr16mXD1X29gZrgUUlN23uD/H28lp09o5TvYVs523ygEFORYsO+TbEOI5cVVTV+XUA1Fu/EBBoxXu0bfnT98cEePa45oUHR7MBHK9IV9Y/BFHFzc7R7/YqF4BsBiDqVBw0NLQoMAAF3c7vwmCEEFln1ZnZxe3wJWx7nZ2jj5qkNDU5l2/ZE3kusjQuRsDxPXYoQFqa6DBIiUmyqKkYwIWAgD35oZAL/mkFwAAAABJRU5ErkJggg=="
          alt=""
        />&nbsp;浙公网安备 33010802012380号</a>

    </div>
  </div>
</template>

<style lang="scss" scoped>
.footer {
  font-size: 14px;
  background: #333;
  padding: 55px 100px 0 100px;
  min-width: 1200px;
  color: #fff;
  .pull-left {
    width: 20%;
    .name {
      text-align: left;
      font-size: 16px;
      margin-bottom: 20px;
      margin-top: 11px;
      font-family: PingFangSC-Regular;
    }
  }
  .pull-right {
    display: flex;
    width: 30%;
    justify-content: space-around;
    .name {
      text-align: center;
      font-size: 16px;
      margin-bottom: 20px;
      font-weight: 400;
      margin-top: 11px;
      font-family: PingFangSC-Regular;
    }
  }

  ul {
    padding-bottom: 20px;
    li {
      text-align: left;
      line-height: 18px;
      padding-bottom: 12px;
      a {
        color: #fff;
        font-family: PingFangSC-Light;
      }
      a:hover {
        color: #f9d230;
      }
    }
  }
  .box1 {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding-bottom: 77px;
  }
  .box2 {
    padding: 30px 0;
    font-size: 14px;
    font-family: PingFangSC-Light;
    a {
      color: #fff;
    }
  }
}
</style>