<template>
  <el-container>
    <el-header>
      <div class="header-left">
        <img src="../../assets/images/home_img_Logo_nor.png" alt="" />
        <span>高品质出行 有我有礼</span>
      </div>
      <ul class="clearfix">
        <li>
          <a href="javascript:;" @click="changeIndex(0)"
            >首页 <span class="line" v-if="activeIndex == 0"></span
          ></a>
        </li>
        <li>
          <a href="javascript:;" @click="changeIndex(1)"
            >预约出行 <span class="line" v-if="activeIndex == 1"></span
          ></a>
        </li>
        <li>
          <a href="javascript:;" @click="changeIndex(2)"
            >英伦座驾 <span class="line" v-if="activeIndex == 2"></span
          ></a>
        </li>
        <li>
          <a href="javascript:;" @click="changeIndex(3)"
            >品质服务 <span class="line" v-if="activeIndex == 3"></span
          ></a>
        </li>
        <li>
          <a href="javascript:;" @click="changeIndex(4)"
            >百年经典 <span class="line" v-if="activeIndex == 4"></span
          ></a>
        </li>
        <!-- <li>
          <a href="javascript:;" @click="changeIndex(6)"
            >政企服务 <span class="line" v-if="activeIndex == 6"></span
          ></a>
        </li> -->
        <li>
          <a href="javascript:;" @click="changeIndex(5)"
            >司机招募 <span class="line" v-if="activeIndex == 5"></span
          ></a>
        </li>
      </ul>
    </el-header>
    <el-main>
      <section class="aggrement">
        <h2 data-v-f0249e52="">保险条款</h2>
        <h4 data-v-f0249e52="">一、平台承运人责任险</h4>
        <h4 data-v-f0249e52="">承保的对象</h4>
        <p data-v-f0249e52="">
          甲方在乙方投保车辆的承运人责任保险（包括乘客、司机），投保车型：本次报价适用于浙江礼帽出行科技有限公司及其相关公司的所有车型，座位涵盖5座、6座及7座车辆，使用性质不区分（网约、出租），具体详见甲方投保车辆明细。
        </p>
        <h4 data-v-f0249e52="">保险责任范围</h4>
        <p data-v-f0249e52="">
          1．在保险期间内，旅客在乘坐被保险人提供的客运车辆的途中遭受人身伤亡或财产损失，依照中华人民共和国法律（不包括港澳台地区法律）应由被保险人承担的经济赔偿责任，保险人按照本保险合同约定负责赔偿。
          旅客是指持有效运输凭证乘坐客运汽车的人员、按照运输主管部门有关规定免费乘坐客运车辆的儿童以及按照承运人规定享受免票待遇的人员。财产损失指旅客托运行李及随身携带物品的损失。
        </p>
        <p data-v-f0249e52="">
          2．保险事故发生后，被保险人因保险事故而被提起仲裁或者诉讼的，对应由被保险人支付的仲裁或诉讼费用以及事先经保险人书面同意支付的其它必要的、合理的费用（以下简称“法律费用”），保险人按照本保险合同约定也负责赔偿。
        </p>
        <p data-v-f0249e52="">
          3．在保险期间内，被保险人在使用客运车辆从事合法客运经营过程中发生意外事故，导致本车驾驶员人身伤亡，依照中华人民共和国法律（不包括港澳台地区法律）应由被保险人承担的经济赔偿责任，保险人按照本保险合同约定负责赔偿。
          责任限额
        </p>
        <p data-v-f0249e52="">1．单次单人死亡伤残赔偿限额：120万；</p>
        <p data-v-f0249e52="">2．单次单人医疗费用赔偿限额：12万；</p>
        <p data-v-f0249e52="">3．单次单车财产损失赔偿限额：1万；</p>
        <p data-v-f0249e52="">4. 误工费赔偿：</p>
        <p data-v-f0249e52="">
          4.1有固定收入的：误工费=误工收入(元/天)*误工时间（天)
        </p>
        <p data-v-f0249e52="">
          注：对有固定收入的，应当按照其实际产生的损失计算误工费。
        </p>
        <p data-v-f0249e52="">
          4.2 无固定收入的：受害人能证明其最近三年的平均收入状况的：
        </p>
        <p data-v-f0249e52="">
          误工费=误工时间(天)*最近三年的平均收入水平(元/天)
        </p>
        <p data-v-f0249e52="">
          注：收入状况的证明包括纳税凭证、单位出具的证明等。
        </p>
        <p data-v-f0249e52="">4.3 受害人不能证明其最近三年的平均收入状况的</p>
        <p data-v-f0249e52="">
          误工费=误工时间(天)*相通、相近行业的上一年职工平均工资(元/天)
        </p>
        <p data-v-f0249e52="">5. 免赔额：无。</p>
        <h4 data-v-f0249e52="">二、车辆营运险</h4>
        <p data-v-f0249e52="">交强险、商业险车损、三者150万元</p>
        <h4 data-v-f0249e52="">三、责任事故先行垫付方案</h4>

        <h5>(一) 先行赔付用途类型</h5>
        <p>1,人伤案件损失</p>
        <p>2,重大车损或物损纠纷损失</p>
        <p>3,其他造成损失</p>

        <h5>(二) 操作流程:</h5>
        <p><b>1,启动条件:司机提交《人伤费用垫付申请表》及以下材料:</b></p>
        <p>A医院提供:诊断报告、医院催款单,等</p>
        <p>B交警提供:道路交通事故抢救费垫付通知书,等</p>
        <p>C其他:其他所需资料,等</p>
        <p><b>2,流程审批及时效:</b></p>
        <p>保险岗(提交)一城市经理(审核)一渠道部(保险板块)</p>
        <p>(审核)一平台相关领导(审核)一财务部(审核,打款)。</p>
        <p>财务部审核时效性要求:提交后1个工作日内。</p>
        <p>流程审批时效性要求:2个工作日内闭环。</p>
        <p><b>3,案件跟踪闭环:</b></p>
        <p>由保险岗负责医疗费付款,单据回收,预付结 案,款项回款等全闭环处理。</p>
        <p><b>4,资金回款档案</b></p>
        <p>
          结案汇款:案件完结后,由保险岗提报理赔款打款凭证,冲抵垫付
          款;案件所涉及材料,单据等电子归档,并提交至总部保险板块;
          款项催收:未结案件,因结案周期长等原因,会造成大量垫付款项回款
          慢,现保险岗负责对案件跟踪记录,催收汇款。
        </p>
        <p><img src="/aggrement.png" style="max-width: 100%" /></p>
      </section>
      <Footer />
    </el-main>
  </el-container>
</template>

<script>
import { mapState } from "vuex";
import Footer from "./footer.vue";
export default {
  components: {
    Footer,
  },
  computed: {
    ...mapState({
      homeIndex: (store) => store.common.homeIndex,
    }),
  },
  mounted() {
    this.$store.commit("loading/HIDELOADING");
  },
  methods: {
    changeIndex(index) {
      this.$store.commit("common/HOMEINDEX", index);
      this.$router.push("/");
    },
  },
};
</script>

<style scoped lang="scss">
.el-container {
  background: #fff;
  // height: 100vh;
  color: #222;
  .el-header {
    padding: 15px 60px;
    position: fixed;
    top: 0;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    margin: 0px auto;
    background: #ffffff;
    font-size: 14px;
    min-width: 1200px;
    height: 60px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
    z-index: 999;
    .header-left {
      img {
        display: inline-block;
        height: 30px;
        vertical-align: middle;
        display: inline-block;
        padding-right: 10px;
      }
      span {
        vertical-align: middle;
        border-left: 1px solid #e6e6e6;
        display: inline-block;
        padding-left: 10px;
        font-family: PingFangSC-Light;
        border-radius: 1px;
      }
    }
    ul {
      li {
        float: left;
        a {
          margin-left: 70px;
          display: inline-block;
          line-height: 30px;
          font-family: PingFangSC-Regular;
          position: relative;
          height: 32.5px;
        }
        a:after {
          content: " ";
          position: absolute;
          z-index: 2;
          bottom: 0px;
          left: 50%;
          display: block;
          width: 18px;
          height: 1px;
          transform: translate(-50%);
        }
        a:hover:after {
          height: 2px;
          animation: ad_width 0.1s linear forwards;
          background: #181818;
        }
        .line {
          box-sizing: border-box;
          line-height: 10px;
          display: block;
          width: 18px;
          height: 2.5px;
          border: 1px solid #181818;
          border-radius: 20%;
          background: #181818;
          margin: 0 auto;
        }
        @keyframes ad_width {
          from {
            width: 0;
          }

          to {
            width: 18px;
          }
        }
      }
    }
  }
  .el-main {
    // height: calc(100vh - 60px);
    overflow-x: hidden;
    padding: 0;
    // margin-top: 60px;
    width: 100%;
    #infomation7 {
      height: 890px;
      margin-top: 60px;
      background: url("../../assets/images/server-home.png") no-repeat;
      background-size: cover;
      position: relative;
    }
    #infomation7 .server-box {
      position: absolute;
      right: 160px;
      top: 253px;
      width: 420px;
      height: 384px;
      background-color: rgba(0, 0, 0, 0.5);
      backdrop-filter: blur(20px);
      text-align: center;
      overflow: hidden;
      padding: 48px 60px 30px;
      box-sizing: border-box;
    }

    #infomation7 .server-box .title {
      font-size: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 28px;
      padding-bottom: 0;
    }
    ::v-deep .server-box {
      .el-input {
        margin-top: 20px;
        .el-input__inner {
          color: #fff;
          border-radius: 0;
          border-top: 0;
          border-left: 0;
          border-right: 0;
          background-color: inherit;
          text-align: center !important;
        }
      }
    }
    #infomation7 .button-box {
      .el-button {
        margin-top: 50px;
        width: 100%;
        background: #f9d230;
        border-radius: 6px;
        border-color: #f9d230;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #17191e;
      }
    }
    .server-btitle {
      padding-top: 100px;
      text-align: center;
      font-size: 40px;
      font-family: PingFangSC-Light, PingFang SC;
      font-weight: 300;
      color: #222222;
      line-height: 44px;
    }

    .server-stitle {
      padding-top: 24px;
      font-size: 20px;
      font-family: PingFangSC-Light, PingFang SC;
      font-weight: 300;
      color: #222222;
      line-height: 24px;
    }
    .server-flex-box {
      padding: 71px 179px 124px;
      margin-left: -40px;
      margin-right: -40px;
      width: calc(100% + 80px);
      display: flex;
      justify-content: space-between;
      .flex-child {
        width: calc(50% - 40px);
        .img-box {
          // height: 452px;
          img {
            width: 100%;
            height: 100%;
            display: inline-block;
          }
        }
        .flex-child-title {
          font-size: 24px;
          font-family: PingFang-SC-Medium, PingFang-SC;
          font-weight: 500;
          color: #222222;
          line-height: 28px;
          text-align: left;
          padding-top: 23px;
        }
      }
    }
    .yl-box {
      background-color: #f8f8f8;
    }
    .server-flex-yl {
      padding: 71px 179px 124px;
      margin-left: -15px;
      margin-right: -15px;
      width: calc(100% + 30px);
      transform: translate3d(0, 0, 0);
      overflow: hidden;
      display: flex;
      justify-content: space-between;
      .flex-child {
        width: calc(33% - 30px);
        .img-box {
          // height: 430px;
          img {
            width: 100%;
            // height: 430px;
            display: inline-block;
          }
        }
        .yl-child-title {
          padding-top: 23px;
          text-align: left;
          font-size: 24px;
          font-family: PingFang-SC-Medium, PingFang-SC;
          font-weight: 500;
          color: #222222;
          line-height: 28px;
        }
        .yl-child-title2 {
          padding-top: 16px;
          text-align: left;
          font-size: 16px;
          font-family: PingFangSC-Light, PingFang SC;
          font-weight: 300;
          color: #222222;
          line-height: 20px;
        }
      }
    }

    .first-box,
    .six-box {
      width: 100%;
      img {
        display: inline-block;
        width: 100%;
      }
    }
    .first-box {
      .kong {
        height: 60px;
      }
    }
    .forth-box {
      font-family: PingFangSC-Light;
      height: 890px;
      background: url("../../assets/images/bg.png") no-repeat;
      background-size: cover;
      .name {
        font-size: 40px;
        color: #222222;
        padding: 100px 0 120px 0;
      }
      .rel-box {
        position: relative;
        display: inline-block;
        margin: 0 auto;
        img {
          display: inline-block;
          width: 873px;
          height: 440px;
        }
        span {
          font-family: PingFangSC-Regular;
          color: #222;
        }
        .sp1 {
          position: absolute;
          top: 0;
          left: 40px;
        }
        .sp2 {
          position: absolute;
          top: -30px;
          left: 265px;
        }
        .sp3 {
          position: absolute;
          bottom: 152px;
          left: -135px;
        }
        .sp4 {
          position: absolute;
          bottom: -25px;
          left: 187px;
        }
      }
    }
    .second {
      background: #f8f8f8;
      padding: 95px 60px;
      min-width: 1200px;
    }

    .second-box {
      margin: 0 auto;
      .img-box {
        width: 46%;
        img {
          display: inline-block;
          width: 100%;
          //   height: 700px;
        }
      }
      .logo-box {
        padding-left: 60px;
        width: 54%;
        .info-box1 {
          font-family: PingFangSC-Regular;
          margin-top: 60px;
          font-size: 24px;
          color: #222222;
          text-align: left;
          line-height: 50px;
          // text-overflow: -o-ellipsis-lastline;
          // overflow: hidden;
          // text-overflow: ellipsis;
          // display: -webkit-box;
          // -webkit-line-clamp: 2;
          // line-clamp: 2;
          // -webkit-box-orient: vertical;
        }
        @media only screen and (min-width: 1281px) {
          .info-box1 {
            font-size: 32px;
          }
        }
        .info-box2 {
          font-size: 12px;
          color: #222222;
          text-align: left;
          line-height: 30px;
          margin-top: 40px;
          margin-bottom: 40px;
          // text-overflow: -o-ellipsis-lastline;
          // overflow: hidden;
          // text-overflow: ellipsis;
          // display: -webkit-box;
          // -webkit-line-clamp: 2;
          // line-clamp: 2;
          // -webkit-box-orient: vertical;
          font-family: PingFangSC-Regular;
        }
        @media only screen and (min-width: 1281px) {
          .info-box2 {
            font-size: 18px;
          }
        }
        .img-box2 {
          text-align: center;
          img {
            display: inline-block;
            width: 240px;
            height: 220px;
            //   height: 200px;
          }
        }
        @media only screen and (min-width: 1440px) {
          img {
            margin-top: 0px;
          }
        }
        @media only screen and (min-width: 1680px) {
          img {
            margin-top: 70px;
          }
        }
      }
    }

    .third-box {
      background: #ffffff;

      .title {
        font-family: PingFangSC-Light;
        padding: 100px 0 120px 0;
        text-align: center;
        color: #222222;
        font-size: 40px;
      }
    }
    .five-box {
      background: #f8f8f8;
      padding-bottom: 120px;
      .info {
        font-family: PingFangSC-Light;
        text-align: center;
        color: #222222;
        font-size: 44px;
        padding-top: 100px;
      }
      .sinfo {
        font-family: PingFangSC-Light;
        text-align: center;
        color: #222222;
        font-size: 20px;
        margin-top: 24px;
        margin-bottom: 72px;
      }
      .img-box {
        display: flex;
        padding: 0 190px;
        justify-content: space-between;
        margin-left: -15px;
        margin-right: -15px;
        width: calc(100% + 30px);
        .five-img-box {
          width: calc(33% - 30px);
          margin: 15px;
          .simg-box {
            width: 100%;
            img {
              display: inline-block;
              width: 100%;
            }
          }
          .title {
            text-align: left;
            font-size: 24px;
            margin-top: 24px;
            font-family: PingFang-SC-Medium;
          }
          .stitle {
            font-family: PingFangSC-Light;
            text-align: left;
            font-size: 16px;
            margin-top: 16px;
          }
        }
      }
    }
    .swiper-box {
      display: flex;
      padding: 0 190px 120px 190px;
      justify-content: space-between;
    }
    .swiper-container {
      margin-left: -15px;
      margin-right: -15px;
      width: calc(100% + 30px);
      transform: translate3d(0, 0, 0);
      overflow: hidden;
      .banner-swiper-item {
        transform: translate3d(0, 0, 0);
        border-radius: 4px;
        // width: 100%;
        width: calc(33% - 30px);
        height: 100%;
        overflow: hidden;
        display: block;
        position: relative;
        img {
          display: inline-block;
          width: 100%;
          height: 100%;
        }
        .swiper-info {
          text-align: left;
          font-size: 24px;
          color: #222222;
          font-family: PingFang-SC-Medium;
          margin-top: 24px;
        }
        .swiper-sinfo {
          text-align: left;
          font-size: 16px;
          color: #222222;
          font-family: PingFangSC-Light;
          margin-top: 16px;
          line-height: 16px;
        }
      }
    }
    .seven-box {
      padding-top: 100px;
      background: #fff;
      padding-bottom: 139px;
      .info {
        font-family: PingFangSC-Light;
        text-align: center;
        color: #222222;
        font-size: 40px;
        // margin-top: 100px;
      }
      .sinfo {
        font-family: PingFangSC-Light;
        text-align: center;
        color: #222222;
        font-size: 20px;
        margin-top: 24px;
        margin-bottom: 72px;
      }
      .seven-img-box {
        display: flex;
        padding: 0 60px;
        justify-content: space-between;
        margin-left: -15px;
        margin-right: -15px;
        width: calc(100% + 30px);
        min-width: 1200px;
        .img-box {
          width: calc(25% - 30px);
          margin: 15px;
          background: #f1f1f1;
          .simg-box {
            width: 100%;
            img {
              display: inline-block;
              width: 100%;
            }
          }
          .title {
            text-align: left;
            font-size: 18px;
            margin-top: 10px;
            padding-left: 13px;
            // padding-bottom: 13px;
            font-family: PingFangSC-Regular;
          }
          .stitle {
            padding: 0 13px 13px 13px;
            text-align: left;
            font-size: 14px;
            margin-top: 8px;
            line-height: 16px;
            font-family: PingFangSC-Light;
          }
        }
      }
    }
    .nine-box {
      padding-top: 100px;
    }
    .manager-box {
      width: 52%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      // padding-left: 117px;
      .title {
        font-family: PingFangSC-Light;
        padding-bottom: 100px;
        font-size: 40px;
        color: #222222;
      }
      .input-box {
        // margin-left: 93px;
        // width: 360px;
        margin-bottom: 30px;
        .el-input {
          width: 360px;
          .el-input__inner {
            height: 50px;
            width: 360px;
            font-family: PingFang-SC-Medium;
          }
        }
      }
      .button-box {
        padding-bottom: 136px;
        .el-button {
          background: #181818;
          width: 160px;
          border-color: #181818;
        }
      }
    }
    .eight-box {
      display: flex;
      min-width: 1200px;
      .left-box {
        width: 48%;
        img {
          display: inline-block;
          width: 100%;
          height: 100%;
        }
      }
      .right-box {
        width: 52%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        // padding-left: 117px;
        background: #f8f8f8;
        padding-top: 60px;
        .title {
          font-family: PingFangSC-Light;
          padding-bottom: 100px;
          font-size: 40px;
          color: #222222;
        }
        .input-box {
          // margin-left: 93px;
          // width: 360px;
          margin-bottom: 30px;
          .el-input {
            width: 360px;
            .el-input__inner {
              height: 50px;
              width: 360px;
              font-family: PingFang-SC-Medium;
            }
          }
        }
        .button-box {
          padding-bottom: 136px;
          .el-button {
            background: #181818;
            width: 160px;
            border-color: #181818;
          }
        }
      }
    }
  }
}
.aggrement {
  padding: 80px;
  font-size: 14px;
  text-align: left;
  line-height: 24px;
}
.el-container h2 {
  height: 26px;
  font-size: 22px;
  text-align: center;
  margin: 0 0 16px;
}
.el-container h4 {
  margin: 10px 0 0;
  font-size: 14px;
  text-align: left;
}
.el-container h5 {
  margin: 10px 0 0;
  font-size: 14px;
  text-align: left;
}
.el-container p {
  text-indent: 2em;
}
</style>
